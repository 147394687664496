import React from 'react'
import './Dots.css'

export default function Dots() {
  return (
    <section className='section-dots'>
        <div className='dots first'></div>
        <div className='dots second'></div>
        <div className='dots third'></div>
    </section>
    
  )
}


