const partnersPhotos = [
    {
        id:1,
        img: require ('./photos/fero-term-partner.jpeg')
    },
    {
        id:2,
        img: require ('./photos/vaillant-partner.png')
    },
    {
        id:3,
        img: require ('./photos/frigo-ve-partner.png')
    },
    {
        id:4,
        img: require ('./photos/frigokor-partner.png')
    }, 
    {
        id:5,
        img: require ('./photos/keraterm-partner.png')
    },
    {
        id:6,
        img: require ('./photos/ekoplam-partner.png')
    },
    {
        id:7,
        img: require ('./photos/petrokrov-partner.jpeg')
    }
]
export default partnersPhotos