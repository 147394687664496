import React from "react";
import OurServices from "../../components/Services-components/OurServices/OurServices";

function Services() {
  return (
    <div>
      <OurServices />
    </div>
  );
}

export default Services;
