const cooperants = [
    {
        id:1,
        name: 'Plinoservis MAT-RA',
        img: require ('./photos/kooperant1.png')
    },
    {
        id:2,
        name: 'MB THERMO',
        img: require ('./photos/kooperant2.jpg')
    }
]

export default cooperants