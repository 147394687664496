import ContactSection from "../../components/Contact-components/contact-text/ContactSection";

function Contact() {
  return (
    <>
      <ContactSection />
    </>
  );
}

export default Contact;
